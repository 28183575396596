import React from 'react';

const Languages = React.lazy(() => import('pages/System/Languages'));

//Main Route
const Dashboard = React.lazy(() => import('pages/Dashboard'));

//Shopping
const Shopping = React.lazy(() => import('pages/Shopping'));
const ShoppingOrderDetail = React.lazy(() => import('pages/Shopping/OrderDetail'));
const RequestPaymentOrder = React.lazy(() => import('pages/Shopping/RequestPaymentOrder'));
const ImportWarehouse = React.lazy(() => import('pages/Shopping/ImportWarehouse'));
const RequestPaymentSystemFee = React.lazy(() => import('pages/Shopping/RequestPaymentSystemFee'));
const EditOrderInformation = React.lazy(() => import('pages/Shopping/EditOrderInformation'));
const ImportWarehouseMultiple = React.lazy(() => import('pages/Shopping/ImportWarehouseMultiple'));

//Shipping
const Shipping = React.lazy(() => import('pages/Shipping'));
const ShippingCreateOrder = React.lazy(() => import('pages/Shipping/CreateOrder'));
const ShippingOrderDetail = React.lazy(() => import('pages/Shipping/OrderDetail'));
const ImportWarehouseShipping = React.lazy(() => import('pages/Shipping/ImportWarehouse'));
const ShippingEditOrderInformation = React.lazy(() => import('pages/Shipping/EditOrderInformation'));
const ShippingRequestPaymentOrder = React.lazy(() => import('pages/Shipping/EditRequestPayment'));
const ShippingImportWarehouseMultiple = React.lazy(() => import('pages/Shipping/ImportWarehouseMultiple'));

//Shipping From Address
const ShippingFromAddress = React.lazy(() => import('pages/ShippingFromAddress'));
const ShippingFromAddressCreateOrder = React.lazy(() => import('pages/ShippingFromAddress/CreateOrder'));
const ShippingFromAddressOrderDetail = React.lazy(() => import('pages/ShippingFromAddress/OrderDetail'));
const ShippingFromAddressEditOrderInformation = React.lazy(() => import('pages/ShippingFromAddress/EditOrderInformation'));
const ShippingFromAddressRequestPaymentOrder = React.lazy(() => import('pages/ShippingFromAddress/EditRequestPayment'));

//Order Error
const OrderError = React.lazy(() => import('pages/OrderError'));
const OrderShoppingErrorDetail = React.lazy(() => import('pages/OrderError/OrderDetail/OrderShopping'));
const OrderShippingErrorDetail = React.lazy(() => import('pages/OrderError/OrderDetail/OrderShipping'));

//Configs
const Roles = React.lazy(() => import('pages/System/Roles'));
const Configs = React.lazy(() => import('pages/Configs'));
const ConfigSystem = React.lazy(() => import('pages/ConfigSystem'));
const AccountProfile = React.lazy(() => import('pages/Profile/ChangeProfile'));
const ChangePassword = React.lazy(() => import('pages/Profile/ChangePassword'));
const Staff = React.lazy(() => import('pages/System/Staffs'));
const StaffCreate = React.lazy(() => import('pages/System/Staffs/StaffCreate'));
const StaffEdit = React.lazy(() => import('pages/System/Staffs/StaffEdit'));
const MailTemplate = React.lazy(() => import('pages/MailTemplate'));
const OptionalService = React.lazy(() => import('pages/OptionalService'));
const OptionalServiceEdit = React.lazy(() => import('pages/OptionalService/OptionalServiceEdit'));
const OptionalServiceCreate = React.lazy(() => import('pages/OptionalService/OptionalServiceCreate'));
const OptionalServiceCreateLp = React.lazy(() => import('pages/OptionalServiceLp/OptionalServiceCreate'));
const OptionalServiceEditLp = React.lazy(() => import('pages/OptionalServiceLp/OptionalServiceEdit'));

const OptionalServiceEditDocument = React.lazy(() => import('pages/OptionalService/OptionalServiceEditDocument'));
const OptionalServiceLp = React.lazy(() => import('pages/OptionalServiceLp'));
const Customer = React.lazy(() => import('pages/Customer'));
const National = React.lazy(() => import('pages/Configs/National'));
const ReasonReport = React.lazy(() => import('pages/Configs/ReasonReport'));
const Reports = React.lazy(() => import('pages/Configs/Reports'));
const DetailReports = React.lazy(() => import('pages/Configs/Reports/detailReport'));
const Paper = React.lazy(() => import('pages/Configs/Paper'));
const AddDocument = React.lazy(() => import('pages/Configs/Paper/AddTemplate'));
const Template = React.lazy(() => import('pages/Configs/Paper/template'));
const Category = React.lazy(() => import('pages/Configs/Category'));
const Delivery = React.lazy(() => import('pages/Delivery/DeliveryManager/index'));
const DetailCustomer = React.lazy(() => import('pages/Customer/detail'));
const DetailCustomerWaiting = React.lazy(() => import('pages/Customer/infoCustomerWaiting'));
const EditCustomerWaiting = React.lazy(() => import('pages/Customer/editInfoCustomer'));
const AddCustomer = React.lazy(() => import('pages/Customer/addCustomer'));

//Config Warehouse Address
const WarehouseAddress = React.lazy(() => import('pages/Configs/WarehouseAddress'));

//Config Domestic Fee
const ConfigDomestic = React.lazy(() => import('pages/ConfigDomestic'));

//CommissionFee Fee
const CommissionFee = React.lazy(() => import('pages/CommissionFee'));

//Notification
const Notification = React.lazy(() => import('pages/Notification'));
const AddNotification = React.lazy(() => import('pages/Notification/AddTemplate'));
const EditNotification = React.lazy(() => import('pages/Notification/EditTemplate'));

//Revenue
const Revenue = React.lazy(() => import('pages/Revenue'));

//Invoice
const Invoice = React.lazy(() => import('pages/Invoice'));

//InvoiceRecurring
const InvoiceRecurring = React.lazy(() => import('pages/InvoiceRecurring'));
const InvoiceRecurringDetail = React.lazy(() => import('pages/InvoiceRecurring/detail'));

//Warehouse
const Warehouse = React.lazy(() => import('pages/Warehouse'));

//Developing
const Page500 = React.lazy(() => import('pages/Page500'));

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/shipping', name: 'Shipping List', component: Shipping, exact: true },
  { path: '/shipping/create-order', name: 'Shipping Create Order', component: ShippingCreateOrder, exact: true },
  { path: '/shipping/detail/:orderId', exact: true, name: 'Detail Order Shipping', component: ShippingOrderDetail },
  {
    path: '/shipping/import-warehouse/:orderId',
    exact: true,
    name: 'Import Warehouse Shipping',
    component: ImportWarehouseShipping,
  },
  { path: '/shipping/order-information/edit/:orderId', exact: true, name: 'Edit Order Information Shipping', component: ShippingEditOrderInformation },
  {
    path: '/shipping/request-payment/:orderId',
    exact: true,
    name: 'Request Payment Order',
    component: ShippingRequestPaymentOrder,
  },
  {
    path: '/shipping/import-warehouse-multiple',
    exact: true,
    name: 'Shipping Import Warehouse Multiple',
    component: ShippingImportWarehouseMultiple,
  },
  { path: '/shipping-from-address', name: 'Shipping List', component: ShippingFromAddress, exact: true },
  { path: '/shipping-from-address/create-order', name: 'Shipping Create Order', component: ShippingFromAddressCreateOrder, exact: true },
  { path: '/shipping-from-address/detail/:orderId', exact: true, name: 'Detail Order Shipping', component: ShippingFromAddressOrderDetail },
  { path: '/shipping-from-address/order-information/edit/:orderId', exact: true, name: 'Edit Order Information Shipping', component: ShippingFromAddressEditOrderInformation },
  {
    path: '/shipping-from-address/request-payment/:orderId',
    exact: true,
    name: 'Request Payment Order',
    component: ShippingFromAddressRequestPaymentOrder,
  },
  { path: '/roles', name: 'Roles', component: Roles, exact: true },
  { path: '/languages', name: 'Languages', component: Languages },
  { path: '/configs', name: 'Configs', component: Configs },
  { path: '/config-system', name: 'Config System', component: ConfigSystem },
  { path: '/config-fee-general', name: 'Config System', component: ConfigDomestic },
  { path: '/commission-fee', name: 'CommissionFee', component: CommissionFee },
  { path: '/notification', exact: true, name: 'Notification', component: Notification },
  { path: '/notification/add', exact: true, name: 'Add Notification', component: AddNotification },
  { path: '/notification/edit/:id', exact: true, name: 'Edit Notification', component: EditNotification },
  { path: '/account/profile', name: 'Account Profile', component: AccountProfile },
  { path: '/account/change-password', name: 'Account Profile', component: ChangePassword },
  { path: '/staffs', exact: true, name: 'Staff', component: Staff },
  { path: '/staffs/create', exact: true, name: 'Staff Create', component: StaffCreate },
  { path: '/staffs/edit/:staffId', exact: true, name: 'Staff Edit', component: StaffEdit },
  { path: '/mail-template', exact: true, name: 'Mail Template', component: MailTemplate },
  { path: '/optional-service', exact: true, name: 'Optional Service', component: OptionalService },
  { path: '/optional-service-lp', exact: true, name: 'Optional Service', component: OptionalServiceLp },
  {
    path: '/optional-service/edit/:optionId',
    exact: true,
    name: 'Optional Service Edit',
    component: OptionalServiceEdit,
  },
  {
    path: '/optional-service/edit/lp/:optionId',
    exact: true,
    name: 'Optional Service Edit Lp',
    component: OptionalServiceEditLp,
  },
  { path: '/optional-service/create', exact: true, name: 'Optional Service Create', component: OptionalServiceCreate },
  { path: '/optional-service/create/lp', exact: true, name: 'Optional Service Create LP', component: OptionalServiceCreateLp },
  {
    path: '/optional-service/edit-document/:optionId',
    exact: true,
    name: 'Optional Service Edit',
    component: OptionalServiceEditDocument,
  },
  {
    path: '/warehouse-address',
    exact: true,
    name: 'Config Warehouse Address',
    component: WarehouseAddress,
  },
  { path: '/shopping', name: 'Shopping List', component: Shopping, exact: true },
  { path: '/shopping/detail/:orderId', exact: true, name: 'Detail Order', component: ShoppingOrderDetail },
  {
    path: '/shopping/request-payment/:orderId',
    exact: true,
    name: 'Request Payment Order',
    component: RequestPaymentOrder,
  },
  { path: '/shopping/import-warehouse/:orderId', exact: true, name: 'Import Warehouse', component: ImportWarehouse },
  {
    path: '/shopping/request-payment-system/:orderId',
    exact: true,
    name: 'Request Payment Fee System',
    component: RequestPaymentSystemFee,
  },
  {
    path: '/shopping/order-information/edit/:orderId',
    exact: true,
    name: 'Edit Order Information',
    component: EditOrderInformation,
  },
  {
    path: '/shopping/import-warehouse-multiple',
    exact: true,
    name: 'Import Warehouse Multiple',
    component: ImportWarehouseMultiple,
  },
  { path: '/order-error', exact: true, name: 'Order Error', component: OrderError },
  { path: '/order-error/shopping/detail/:orderId', exact: true, name: 'Detail Order Shopping Error', component: OrderShoppingErrorDetail },
  { path: '/order-error/shipping/detail/:orderId', exact: true, name: 'Detail Order Shipping Error', component: OrderShippingErrorDetail },
  { path: '/customer', exact: true, name: 'Customer', component: Customer },
  { path: '/national', exact: true, name: 'National', component: National },
  { path: '/reason-report', exact: true, name: 'Reason Report', component: ReasonReport },
  { path: '/report-list', exact: true, name: 'Report List', component: Reports },
  { path: '/detail-report/:id', exact: true, name: 'Detail Report', component: DetailReports },
  { path: '/paper', exact: true, name: 'Paper', component: Paper },
  { path: '/template/:id', exact: true, name: 'Template', component: Template },
  { path: '/template', exact: true, name: 'Add Template', component: AddDocument },
  { path: '/category', exact: true, name: 'Category', component: Category },
  { path: '/delivery', exact: true, name: 'Delivery', component: Delivery },
  { path: '/customer/detail/:id', exact: true, name: 'Detail Customer', component: DetailCustomer },
  {
    path: '/customer/info-customer-waiting/:id',
    exact: true,
    name: 'Detail Customer Waiting',
    component: DetailCustomerWaiting,
  },
  {
    path: '/customer/edit-info-customer/:id',
    exact: true,
    name: 'Edit Customer Waiting',
    component: EditCustomerWaiting,
  },
  { path: '/add-customer', exact: true, name: 'Add Customer', component: AddCustomer },
  { path: '/revenue', exact: true, name: 'Revenue', component: Revenue },
  { path: '/invoice', exact: true, name: 'Invoice', component: Invoice },
  { path: '/invoice-recurring', exact: true, name: 'Invoice Recurring', component: InvoiceRecurring },
  { path: '/invoice-recurring/detail/:id', exact: true, name: 'Detail Invoice Recurring', component: InvoiceRecurringDetail },

  { path: '/warehouse', exact: true, name: 'Warehouse', component: Warehouse },

  { path: '/refund', exact: true, name: 'Page Developing', component: Page500 },
  { path: '/investigation', exact: true, name: 'Page Developing', component: Page500 },
  { path: '/re-import', exact: true, name: 'Page Developing', component: Page500 },
  { path: '/return-fee', exact: true, name: 'Page Developing', component: Page500 },
  { path: '/payment', exact: true, name: 'Page Developing', component: Page500 },
  { path: '/chat', exact: true, name: 'Page Developing', component: Page500 },
  { path: '/bill', exact: true, name: 'Page Developing', component: Page500 },
];

export default routes;
