import { sendGet, sendPost, sendPut } from './axios';

export const getListOrderShipping = (payload: any) => sendGet(`/cms/order/shipping`, payload);

export const getStaticOrderShipping = () => sendGet(`/cms/order/shipping/statis-order`);

export const createOrderShipping = (payload: any) => sendPost(`/cms/order/shipping/create-order`, payload);

export const createTransportOrders = (orderIds: number[]) =>
  sendPost(`/cms/order/create-transport-order`, { orderIds });

export const requestPickupOrders = (orderIds: number[]) => sendPost(`/cms/order/request-pickup`, { orderIds });

export const getFeeQuote = (payload: any) => sendGet(`/cms/order/shipping/system-fee-quote`, payload);

export const getListCustomer = () => sendGet(`/cms/order/shipping/list-customer`);

export const getDetailCustomer = (customerId: number) => sendGet(`/cms/order/${customerId}/detail`);

export const getDetailOrderShipping = (orderId: number) => sendGet(`/cms/order/shipping/detail/${orderId}`);

export const createTransportOrder = (orderId: number) =>
  sendPost(`/cms/order/shipping/create-transport-order/${orderId}`);

export const deliverStock = (orderId: number) => sendPost(`/cms/order/shipping/stock-delivering/${orderId}`);

export const confirmPayment = (orderId: number) => sendPut(`/cms/order/shipping/confirm-payment/${orderId}`);

export const importWarehouse = (orderId: number, payload: any) =>
  sendPost(`/cms/order/shipping/import-warehouse/${orderId}`, payload);

export const getSystemFee = (orderId: number, payload: any) =>
  sendGet(`/cms/order/shipping/system-fee/${orderId}`, payload);

export const requestPaymentAdditional = (orderId: number, payload: any) =>
  sendPost(`/cms/order/shipping/request-payment-additional/${orderId}`, payload);

export const cancelRequestPayment = (orderId: number, payload: any) =>
  sendPut(`/cms/order/shipping/cancel-request-payment-additional/${orderId}`, payload);

export const updateRequestPayment = (orderId: number, payload: any) =>
  sendPut(`/cms/order/shipping/request-payment/${orderId}`, payload);

export const updateSenderAddressShipping = (orderId: number, payload: any) =>
  sendPut(`/cms/order/shipping/sender-address/${orderId}`, payload);

export const updateReceiverAddressShipping = (orderId: number, payload: any) =>
  sendPut(`/cms/order/shipping/receiver-address/${orderId}`, payload);

export const importWarehouseMulti = (payload: any) => sendPost(`/cms/order/shipping/import-warehouse`, payload);

export const cancelOrder = (orderId: number) => sendPut(`/cms/order/shipping/cancel-order/${orderId}`);

//Shipping From Home
export const getStaticOrderShippingFromHome = () => sendGet(`/cms/order/shipping/statis-order-from-home`);

export const createOrderShippingFromHome = (payload: any) =>
  sendPost(`/cms/order/shipping/create-order-from-home`, payload);

export const updateOrder = (orderId: number, payload: any) =>
  sendPut(`/cms/order/shipping/update-order/${orderId}`, payload);

export const requestPickupShippingFromHome = (orderId: number) =>
  sendPost(`/cms/order/shipping/request-pickup/${orderId}`);

export const deliverStockMulti = (payload: any) => sendPost(`/cms/order/shipping/stock-delivering`, payload);
